import "react-modern-calendar-datepicker/lib/DatePicker.css";

import { useMemo, useState } from "react";
import InputDatePicker from "react-modern-calendar-datepicker";

import { useFormCurrentPage } from "../contexts/formCurrentPage";

import theme from "../styles/theme";
import styled from "styled-components";
import { myCustomLocale } from "../utils/calendarUtil";

const DatePickerWrapper = styled.div`
  font-family: Epilogue, Roboto, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 54px;
  /* border: 1px solid rgba(0, 0, 0, 0.23); */

  .DatePicker__input {
    font: inherit;
    letter-spacing: inherit;
    color: #333;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    /* width: 100%; */
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    margin: auto;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #757575;
      opacity: 1; /* Firefox */
    }
  }

  .DatePicker {
    width: 100%;
    /* height: 56px; */

    input {
      margin-top: -5px;
    }

    input:focus-visible {
      outline: none;
    }

    z-index: ${({ zIndex }) => zIndex ? zIndex : 100};
  }

  fieldset {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;

    legend {
      padding: 0;
      text-align: left;
      transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      line-height: 11px;
    }
  }
`;

const parseDateToObject = (date) => {
  if (date) {
    const dateISO = new Date(date);

    const day = dateISO?.getDate();
    const month = dateISO?.getMonth() + 1;
    const year = dateISO?.getFullYear();

    const dateObject = {
      day,
      month,
      year,
    };

    return dateObject;
  }

  return "";
};

const parseObjectToDate = (date) => {
  return new Date(date.year, date.month - 1, date.day).toISOString();
};

export default function DatePicker({ questionIndex, onSaveAnswer }) {
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();
  const zIndexValue = useMemo(() => questionIndex ? 200 - questionIndex : 200, [questionIndex]);

  var dateValueSelected;

  const [datePicked, setDatePicked] = useState(() => {
    if (formCurrentPage.questions[questionIndex]?.answer) {
      const value = formCurrentPage?.questions[questionIndex]?.answer[0].input;

      return parseDateToObject(value);
    }

    return "";
  });

  const isMultiple = useMemo(() => {
    return !!formCurrentPage.multiple;
  }, [formCurrentPage]);

  const formatInputValue = () => {
    const date = parseDateToObject(
      formCurrentPage?.questions[questionIndex]?.answer &&
      formCurrentPage?.questions[questionIndex]?.answer[0]?.input
    );
    if (!date) return "";

    dateValueSelected = {
      day: date.day,
      month: date.month,
      year: date.year
    }

    return `${date.day}/${date.month}/${date.year}
    `;
  };

  function handleNewValue(oldValue, value) {
    const newQuestions = [
      ...oldValue.questions.slice(0, questionIndex),
      {
        ...oldValue.questions[questionIndex],
        answer: [
          { input: parseObjectToDate(value) },
          // { input: e.target.value }
        ],
      },
      ...oldValue.questions.slice(questionIndex + 1),
    ];

    let newValue = {
      ...oldValue,
      questions: newQuestions,
    };

    if (isMultiple) {
      let oldAnswers = oldValue.answers || [];

      if (!oldValue.answers) return newValue;

      let questionPositionIndex;

      for (let i = 0; i < oldAnswers.length; i++) {
        for (let a = 0; a < oldAnswers[i].length; a++) {
          const currentAnswer = oldAnswers[i][a];

          if (
            currentAnswer.question_id ===
            formCurrentPage?.questions[questionIndex]?._id
          ) {
            questionPositionIndex = i;
          }
        }
      }

      newValue.answers[questionPositionIndex].push({
        question_id: formCurrentPage?.questions[questionIndex]?._id,
        answer: [
          {
            input: parseObjectToDate(value),
          },
        ],
      });
    }

    return newValue;
  }

  return (
    <DatePickerWrapper zIndex={zIndexValue}>
      <InputDatePicker
        value={dateValueSelected != undefined ? dateValueSelected : ''}
        inputPlaceholder={
          formCurrentPage?.questions[questionIndex]?.text || "Selecione a data"
        }
        colorPrimary={theme.primary}
        calendarPopperPosition="bottom"
        formatInputText={formatInputValue}
        onBlur
        onChange={(value) => {
          setDatePicked(() => parseObjectToDate(value));

          let newValue;

          setFormCurrentPage(
            (oldValue) => (newValue = handleNewValue(oldValue, value))
          );

          if (!newValue) return;

          onSaveAnswer(newValue.questions[questionIndex], questionIndex);
        }}
        locale={myCustomLocale}
        style={{
          width: "100%",
          border: "1px solid #000",
        }}
        shouldHighlightWeekends
        inputName="date-picker"
      />
      <fieldset id="date-picker">
        <legend></legend>
      </fieldset>
    </DatePickerWrapper>
  );
}
