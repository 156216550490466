import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useAuth } from "../../contexts/auth";
import ModalComponent from ".";

function ConfirmationCode({ history, username, openModal, callback }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { verificationCode, sendCode } = useAuth();

  function validateForm() {
    if (code.length === 0) {
      setError("Código é obrigatório");
      return false;
    }
    return true;
  }

  async function confirmCode() {
    setError(null);
    if (validateForm()) {
      setLoading(true);
      try {
        callback ? await callback() : await verificationCode(username, code);
        setLoading(false);

        return history.push("/dashboard");
      } catch (error) {
        setLoading(false);
        setError("Erro ao validar código");
        console.log("error confirmCode", error);
      }
    }
  }

  return (
    <ModalComponent
      open={openModal}
      onClose={() => history.push("/auth/signin")}
      title="Verifique seu e-mail!"
    >
      <div>
        <Typography variant="body1" align="justify">
          Nós enviamos um código por e-mail para você. Digite abaixo para
          confirmar sua conta.
        </Typography>

        <TextField
          variant="outlined"
          label="Código"
          fullWidth
          style={{ marginBottom: 20, marginTop: 12 }}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        {error && (
          <Box mb={2}>
            <Typography variant="body1" align="center" style={{ color: "red" }}>
              <strong> {error}</strong>
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={confirmCode}
          disabled={loading}
          fullWidth
        >
          {loading ? "Carregando..." : "Confirmar"}
        </Button>

        <Box mt={2}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => sendCode(username)}
          >
            Não recebeu o código? Enviar novamente
          </Button>
        </Box>
      </div>
    </ModalComponent>
  );
}

export default ConfirmationCode;
