const theme = {
  primary: '#00A75E',
  primaryLight: '#36D87A',

  gray: '#979797',
  lightGray: '#f4f4f4',
  darkGray: '#766F6F',
}

export default theme;
