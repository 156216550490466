import { Switch } from "react-router-dom";
import Router from "./route";

import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ProfileTest from "../pages/ProfileTest";
import VisaForm from "../pages/VisaForm";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/404";

export default function Routes() {
  return (
    <Switch>
      <Router path="/auth/signin" component={SignIn} />
      <Router path="/auth/signup" component={SignUp} />
      <Router path="/auth/forgot-password" component={ForgotPassword} />

      <Router path="/teste-perfil" component={ProfileTest} />

      {/* PRIVATE */}
      <Router path="/visa/form/:id" isPrivate component={VisaForm} />
      <Router path="/dashboard" isPrivate component={Dashboard} />

      {/* 404 */}
      <Router path="*" component={NotFound} />
      {/* <Route
        path="*"
        render={() => (window.location.href = "https://youvisa.com.br")}
      /> */}
    </Switch>
  );
}
