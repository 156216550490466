import React, { useEffect, useState } from "react";
import { Box, Button, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";

import { UPLOAD_DOCUMENT } from "../services/DocumentUploadService";
import { useFormCurrentPage } from "../contexts/formCurrentPage";

const MAX_SIZE_FILE = 10000000;
const MAX_AMOUNT_FILE = 1;

export default function FileInput({ questionIndex, onSaveAnswer }) {
  const [files, setFiles] = useState(null);
  const [hasFile, setHasFile] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = useState(null);

  const { formCurrentPage, setFormCurrentPage, setIsSaving } =
    useFormCurrentPage();

  useEffect(() => {
    if (
      formCurrentPage.questions[questionIndex].answer &&
      formCurrentPage.questions[questionIndex].answer[0].input
    ) {
      setHasFile(true);
    }

    return () => {
      // console.log("unmount FILEINPUT ");
      setHasFile(false);
      setFiles(null);
    };
  }, [formCurrentPage.questions, questionIndex]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  function showMessage(text, type) {
    setMessage({ text, type });
    setOpenSnackbar(true);
  }

  const handleChange = async (event) => {
    setIsSaving(true);
    const filesSelected = event.target.files;

    if (filesSelected?.length > MAX_AMOUNT_FILE) {
      showMessage(
        `Você só pode adicionar ${MAX_AMOUNT_FILE} arquivos.`,
        "error"
      );
      setIsSaving(false);
      return;
    }

    for (let i = 0; i < filesSelected?.length; i++) {
      const fileToRead = filesSelected[i];

      if (fileToRead.size > MAX_SIZE_FILE) {
        setMessage({
          text: `O arquivo ${fileToRead.name} é muito grande. O tamanho máximo é de 10mb`,
          type: "error",
        });
        setOpenSnackbar(true);
        setIsSaving(false);
        return;
      }
    }

    setFiles([...filesSelected]);

    setTimeout(async () => {
      await submitFile([...filesSelected]);
    }, 1000);
  };

  function saveQuestion(answer, isMultiple = false) {
    let newQuestions = [
      ...formCurrentPage.questions.slice(0, questionIndex),
      {
        ...formCurrentPage.questions[questionIndex],
        answer: [{ input: answer[0] }],
      },
      ...formCurrentPage.questions.slice(questionIndex + 1),
    ];

    let newValue = {
      ...formCurrentPage,
      questions: newQuestions,
    };

    setFormCurrentPage(newValue);
    onSaveAnswer(newValue?.questions[questionIndex], questionIndex, isMultiple);
  }

  async function submitFile(filesSelected) {
    const isMultiple = filesSelected.length > 1;

    let responseMapped = [];
    try {
      for (let i = 0; i < filesSelected.length; i++) {
        const fileToRead = filesSelected[i];
        const response = await UPLOAD_DOCUMENT(fileToRead, fileToRead.type);

        responseMapped.push(response.data);
      }

      saveQuestion(responseMapped, isMultiple);
      showMessage("Arquivo(s) enviado(s) com sucesso.", "success");
      setIsSaving(false);
      return;
    } catch (error) {
      setIsSaving(false);
      console.log("error", error);
      showMessage("Erro ao enviar arquivo(s).", "error");
    }
  }

  return (
    <FileInputContainer>
      {hasFile && !files && (
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={() =>
            window.open(
              formCurrentPage.questions[questionIndex].answer[0].input
            )
          }
        >
          Baixar arquivo selecionado
        </Button>
      )}
      <Box mt={1} />
      <Button
        variant={hasFile ? "text" : "contained"}
        color="primary"
        component="label"
      >
        {hasFile
          ? "Selecionar outro arquivo"
          : formCurrentPage?.questions[questionIndex]?.text ||
            "Selecione o arquivo"}
        <input
          type="file"
          hidden
          onChange={handleChange}
          accept="application/pdf, image/png, image/jpeg"
          // multiple
        />
      </Button>

      <Box mt={1}>
        <Typography variant="subtitle1">
          {files
            ? files.map((file, index) => `${index + 1}.${file.name}`).join(", ")
            : hasFile
            ? ""
            : "Nenhum arquivo selecionado"}
        </Typography>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={message?.type}>
          {message?.text}
        </Alert>
      </Snackbar>
    </FileInputContainer>
  );
}

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
  padding: 20px;
`;
