import {
  Box,
  Button, CircularProgress, Container,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import RenderForm from "../../components/RenderForm";
import { useFormCurrentPage } from "../../contexts/formCurrentPage";
import { useSnackbar } from "../../contexts/snackbar";
import {
  FINISH_VISA_FORM,
  GET_VISA_FORM,
  SAVE_MULTIPLE_ANSWER,
  SAVE_SIMPLE_ANSWER
} from "../../services/VisaFormService";
import ResultVisaForm from "./ResultVisaForm";
import SkeletonLoading from "./skeletonLoading";
import { TitleQuestion } from "./styles";


export default function VisaForm({ match, history }) {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [pages, setPages] = useState(null);
  const [labelNameError, setLabelNameError] = useState(null);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [showResultVisaForm, setShowResultVisaForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstPageOrder, setFirstPageOrder] = useState(null);

  const { setSnackbar } = useSnackbar();
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    formCurrentPage,
    setFormCurrentPage,
    duplicateMultipleQuestion,
    isDisabledButton,
    isSaving,
    setIsSaving,
  } = useFormCurrentPage();
  const formId = match.params.id;

  useEffect(() => {
    setLoading(true);
    async function getForm() {
      setLoading(true);
      try {
        const response = await GET_VISA_FORM(formId);

        setPages(response.data.pages);

        setCurrentOrder(response.data.pages[0].order);
        setFirstPageOrder(response.data.pages[0].order);

        const filteredSteps = response.data.pages?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t?.group === thing?.group)
        );

        let groups = [];
        for (let stp of filteredSteps) {
          groups.push(stp.group);
        }

        setSteps(groups);
      } catch (error) {
        console.log("ERROR ", error);
      } finally {
        setLoading(false);
      }
    }

    getForm();
  }, [formId]);

  useEffect(() => {
    const filteredPage = pages?.find((page) => page.order === currentOrder);

    if (filteredPage?.answers) {
      // duplica a primeira vez
      filteredPage.originalQuestion = filteredPage.questions;

      // let newQuestions = JSON.parse(
      //   JSON.stringify(filteredPage.originalQuestion)
      // );
      const newFilteredPage = JSON.parse(JSON.stringify(filteredPage));

      // console.log("newArray", newArray);

      if (!newFilteredPage.answers) return;

      filteredPage.questions.forEach((question) => (question.required = true));
      filteredPage.originalQuestion.forEach(
        (question) => (question.required = true)
      );

      const { answers } = newFilteredPage;
      const questionsFormatted = [];

      // Colocando as perguntas e respostas em um mesmo objeto.
      for (let i = 0; i < answers.length; i++) {
        for (let a = 0; a < answers[i].length; a++) {
          const currentQuestion = answers[i];
          const currentAnswer = questionsFormatted[a];

          const question =
            currentQuestion[a].answer[0][
            Object.keys(currentQuestion[a].answer[0])[0]
            ];

          if (currentAnswer) {
            currentAnswer[i] = question;
            currentAnswer[`id_${i}`] = currentQuestion[a].question_id;
          } else {
            questionsFormatted.push({
              [i]: question,
              [`id_${i}`]: currentQuestion[a].question_id,
            });
          }
        }
      }

      const questions = [];

      // Colocando perguntas e respostas em objetos diferentes de forma organizada.
      for (let i = 0; i < questionsFormatted.length; i++) {
        for (let a = 0; a < answers.length; a++) {
          if (!newFilteredPage.questions[a]?._id) break;

          questions.push({
            answer: [{ input: questionsFormatted[i][a] }],
            text: newFilteredPage.questions[a]?.text,
            category: newFilteredPage.questions[a]?.category,
            choices_link: newFilteredPage.questions[a]?.choices_link,
            hidden: newFilteredPage.questions[a]?.hidden,
            options: newFilteredPage.questions[a]?.options,
            required: newFilteredPage.questions[a]?.required,
            width: newFilteredPage.questions[a]?.width,
            _id: questionsFormatted[i][`id_${a}`],
          });
        }
      }

      newFilteredPage.questions = questions;

      console.log("newFilteredPage", newFilteredPage);

      toggleTrigger(newFilteredPage);

      setFormCurrentPage(newFilteredPage);
      setActiveStep(steps.findIndex((step) => step === formCurrentPage?.group));
      return;
    }

    console.log("filteredPage", filteredPage);


    setFormCurrentPage(filteredPage);
    setActiveStep(steps.findIndex((step) => step === formCurrentPage?.group));
  }, [currentOrder, formCurrentPage?.group, pages, setFormCurrentPage, steps]);

  async function toggleTrigger(newFilteredPage) {

    if (newFilteredPage.answers) {

      let lastChunkElement = newFilteredPage.answers.length - 1;

      let currentChunk = 0;
      let currentChunkElement = 0;

      for (let i = 0; i < newFilteredPage.questions.length; i++) {
        if (currentChunkElement > lastChunkElement) {
          currentChunkElement = 0;
          currentChunk++;
        }

        let question = newFilteredPage.questions[i];

        if (question.options.length > 0 && question.answer.length > 0) {
          let answer = question.answer[0].input;
          let optionChosen = question.options.find(option => option._id == answer);
          let triggers = optionChosen && optionChosen.trigger ? optionChosen.trigger : [];

          triggers.forEach(trigger => {
            let questionToBeToggle = newFilteredPage.questions.filter(question => question._id == trigger)[currentChunk];
            if (questionToBeToggle)
              questionToBeToggle.hidden = false;
          })

        }

        currentChunkElement++;

      }
    }

  }

  const isLastPage = useMemo(() => {
    if (pages !== null) {
      let pagesLength = pages?.length;
      let lastPage = pages[pagesLength - 1];
      return currentOrder === lastPage.order;
    }
  }, [pages, currentOrder]);

  async function submitForm() {
    console.log("ANSWERS", answers);
    setIsSaving(true);

    try {
      const body = {
        form: formId,
      };

      const response = await FINISH_VISA_FORM(body);
      console.log("RESPONSE submitForm", response.data);

      // setSnackbar({
      //   open: true,
      //   message: "Formulário enviado com sucesso!",
      //   variant: "success",
      // });
      setShowResultVisaForm(true);
    } catch (error) {
      console.log("ERROR submitForm ", error);
      setSnackbar({
        open: true,
        message: "Erro ao enviar formulário!",
        variant: "error",
      });
    } finally {
      setIsSaving(false);
    }
  }

  async function nextAnswer() {
    if (await verifyIfUserCanProceed()) {

      let nextSubPage = null;

      formCurrentPage.questions.forEach(
        (question) => {
          if (question?.answer) {
            const answer = question.answer[0];
            const option = question.options.find((option) => option._id === answer?.option_id);

            if (option?.next_page) {
              nextSubPage = option.next_page;
            }
          }
        }
      )

      const nextPage = nextSubPage || formCurrentPage?.next;
      const previousPage = formCurrentPage?.order;

      if (isLastPage) {
        return submitForm();
      }

      setPages((oldPages) => {
        const newPages = [...oldPages];
        const newPage = newPages.find((page) => page.order === nextPage);

        newPage.previous = previousPage;

        newPages[newPages.findIndex((page) => page.order === currentOrder)] = {
          ...formCurrentPage,
        };

        return newPages;
      });

      setCurrentOrder(nextPage);
      setAnswers((oldAnswers) => [
        ...oldAnswers,
        {
          question_id: formCurrentPage?.questions[0]?._id,
          answers: formCurrentPage?.questions[0]?.answer,
        },
      ]);
    } else {
      console.log("FALTA VALIDACAO ");
    }
  }

  async function previousAnswer() {
    setLabelNameError(null);
    setCurrentOrder(formCurrentPage?.previous);
  }

  async function splitArrayIntoArrayOfChunks(originalArray, chunkSize) {
    let response = [];
    let originalArrayClone = [...originalArray];
    for (let i = 0; i < originalArray.length; i += chunkSize)
      response.push(originalArrayClone.splice(0, chunkSize));
    return response;
  }

  async function getMissingFieldsOnMultipleQuestion(
    questionChunkArray,
    chuckSize,
    hiddenQuestionsAmount
  ) {
    let fieldsRequiredTextArray = [];

    questionChunkArray.forEach((chunk) => {
      let questionWithNoAnswer = chunk.filter(
        (question) => !question.answer && question.hidden == false
      );
      if (
        questionWithNoAnswer.length > 0 &&
        questionWithNoAnswer.length + hiddenQuestionsAmount != chuckSize
      ) {
        fieldsRequiredTextArray.push(
          questionWithNoAnswer.map((question) => question.text)
        );
      }
    });

    return fieldsRequiredTextArray;
  }

  async function getMultipleQuestionsChuckSize(questionArray) {
    let firstArrayElementId = questionArray[0]._id;
    let firtsElementCount = formCurrentPage.questions.filter(
      (question) => question._id == firstArrayElementId
    ).length;
    return questionArray.length / firtsElementCount;
  }

  async function getMissingFieldsOnMultipleAnswer(questionsArray) {
    let chunkSize = await getMultipleQuestionsChuckSize(questionsArray);
    let questionChunkArray = await splitArrayIntoArrayOfChunks(
      questionsArray,
      chunkSize
    );
    let hiddenQuestionsAmount = questionsArray.filter(
      (question) => question.hidden == true
    ).length;
    return await getMissingFieldsOnMultipleQuestion(
      questionChunkArray,
      chunkSize,
      hiddenQuestionsAmount
    );
  }

  async function verifyIfUserCanProceed() {
    setLabelNameError(null);

    let isValid = true;
    if (formCurrentPage?.errorInputMessage) {
      isValid = false;
    }

    if (formCurrentPage?.multiple) {
      let missingFieldsArray = await getMissingFieldsOnMultipleAnswer(
        formCurrentPage.questions
      );

      if (missingFieldsArray.length > 0) {
        setLabelNameError(
          (missingFieldsArray.join(",") || "Todos os campos") +
          " obrigatório(s)"
        );
        isValid = false;
        return false;
      }
    }

    formCurrentPage?.questions?.every((question) => {
      // console.log("QUESTION", question);
      if (question.required) {
        if (!question?.answer) {
          console.log("[INVÁLIDO]", question);
          setLabelNameError(
            (question.text || "Todos os campos") + " é obrigatório"
          );
          isValid = false;
          return false;
        }
      }
      return true;
    });

    if (formCurrentPage?.multiple) {
      console.log("enviando request multipla");

      if (!isValid) return;

      const multipleAnswerSaved = await saveMultipleAnswer();

      return multipleAnswerSaved;
    }

    return isValid;
  }

  const groupBy = (array, key) =>
    array.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});

  async function saveMultipleAnswer() {
    const questionsNormalized = groupBy(formCurrentPage?.questions, "_id");

    let multiple_answers = [];

    Object.keys(questionsNormalized).forEach((key) => {
      const map = questionsNormalized[key].map((question) => ({
        question_id: question._id,
        answer: question.answer ? question.answer : [{ input: "" }],
      }));

      multiple_answers.push(map);
      return map;
    });

    try {
      setIsSaving(true);
      console.log("SAVING MULTIPLE ANSWER");
      const body = {
        form: formId,
        page_number: formCurrentPage?.order,
        multiple_answers,
      };

      const response = await SAVE_MULTIPLE_ANSWER(body);
      console.log("[SAVE MULTIPLE ANSWER]", response.data);
      // setIsSavingMultipleAnswer(false);
      setIsSaving(false);

      return true;
    } catch (error) {
      setIsSaving(false);
      console.log("ERROR MULTIPLE ANSWER ", error);
      setSnackbar({
        open: true,
        message: "Erro ao salvar respostas",
        severity: "error",
      });
      return false;
    }
  }

  async function onSaveAnswer(question, index) {
    if (formCurrentPage?.questions[index]?.answer || question?.answer) {
      if (!formCurrentPage.multiple) {
        try {
          const body = {
            form: formId,
            question_id: question?._id,
            answer: question?.answer
          };

          await SAVE_SIMPLE_ANSWER(body);
        } catch (error) {
          console.log("ERROR onSaveAnswer", error);
        }
      }
    }
  }

  if (loading) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <Header />
      <Container maxWidth="md">
        {!showResultVisaForm && (
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  style={{
                    fontSize: isMobile ? 12 : 14,
                  }}
                >
                  {isMobile ? (activeStep === index ? label : "") : label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Container>
      {showResultVisaForm ? (
        <ResultVisaForm history={history} />
      ) : (
        <Container maxWidth="sm">
          <Grid container>
            <ProgressBar
              value={(formCurrentPage?.order / pages?.length) * 100}
            />
          </Grid>

          <Grid container>
            <Box mb={3}>
              <TitleQuestion>{formCurrentPage?.title}</TitleQuestion>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            {formCurrentPage?.questions?.map((question, index) =>
              question.hidden ? null : (
                <Grid
                  key={String(index)}
                  item
                  className={`MuiGrid-grid-sm-${question.width} MuiGrid-grid-xs-12`}
                >
                  <RenderForm index={index} onSaveAnswer={onSaveAnswer} />
                </Grid>
              )
            )}
            {formCurrentPage?.multiple && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={() => {
                      duplicateMultipleQuestion();
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>

          <Box my={3}>
            {(formCurrentPage?.errorInputMessage || labelNameError) && (
              <Alert severity="error">
                {formCurrentPage?.errorInputMessage ?? labelNameError}
              </Alert>
            )}
          </Box>

          <Box display="flex" justifyContent="space-around" mb={3} mt={3}>
            {currentOrder != firstPageOrder && (
              <Button
                variant="outlined"
                color="primary"
                disabled={isSaving}
                onClick={previousAnswer}
              >
                Voltar
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={isSaving || isDisabledButton}
              onClick={nextAnswer}
            >
              {isSaving ? (
                <CircularProgress size={30} />
              ) : isLastPage ? (
                "Finalizar"
              ) : (
                "Próximo"
              )}
            </Button>
          </Box>
          <Box display="flex" justifyContent="space-around" mb={3}>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSaving}
              onClick={() => {
                verifyIfUserCanProceed();
                history.push("/dashboard");
              }}
            >
              Salvar e sair
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
}
