import axios from "axios";
import { KEY_TOKEN } from "../config/constants";

const getInstance = (url) => {
  return axios.create({
    baseURL: url,
  });
};

const apiInstances = {
  profileTest: getInstance(process.env.REACT_APP_API_PROFILE_TEST),
  visaForm: getInstance(process.env.REACT_APP_API_VISA_FORM),
  choiceLink: getInstance(process.env.REACT_APP_API_CHOICE_LINKS),
  uploadFile: getInstance(process.env.REACT_APP_API_UPLOAD_FILE),
};

const api = apiInstances;

for (let key in api) {
  api[key].interceptors.response.use((response) => response);

  api[key].interceptors.request.use((config) => {
    const token = localStorage.getItem(KEY_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

export default api;
