import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import {
  GET_VISAS_USER,
  START_VISA_FORM,
} from "../../services/VisaFormService";

import { useAuth } from "../../contexts/auth";
import { useSnackbar } from "../../contexts/snackbar";

import Header from "../../components/Header";
import { CardContent } from "./styles";

export default function Dashboard({ history }) {
  const [loading, setLoading] = useState(false);
  const [loadingVisas, setLoadingVisas] = useState(false);
  const [visas, setVisas] = useState([]);

  const { setSnackbar } = useSnackbar();
  const { user } = useAuth();

  const columns = [
    {
      field: "nameApplicant",
      headerName: "Aplicante",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.row.inProgress ? (
          <Chip
            style={{
              backgroundColor: "#F0A92D",
              color: "#fff",
            }}
            label="Em andamento"
            onClick={() => history.push(`/visa/form/${params.row._id}`)}
          />
        ) : (
          <Chip
            style={{
              color: "#fff",
            }}
            color="primary"
            label="Finalizado"
            onClick={() => {}}
          />
        ),
    },
    {
      field: "inProgress",
      headerName: "#",
      width: 160,
      renderCell: (params) =>
        params.row.inProgress ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`/visa/form/${params.row._id}`)}
          >
            Retomar ficha
          </Button>
        ) : (
          ""
        ),
      align: "center",
      headerAlign: "center",
    },
  ];

  async function startForm() {
    setLoading(true);
    try {
      const body = {
        version: 1,
        country: "US",
        category: "TOURIST",
        sheets_id: "1WB8h9-qDLWY4FI0NwvkV5PtiJPBsPspZmBpDXGirpDU",
      };
      const response = await START_VISA_FORM(body);

      setLoading(false);
      setSnackbar({
        open: true,
        message: "Ficha de visto iniciada com sucesso.",
        severity: "success",
      });

      return history.push(`/visa/form/${response.data._id}`);
    } catch (error) {
      console.log("ERROR ", error);
      setSnackbar({
        open: true,
        message: "Erro ao iniciar formulário.",
        severity: "error",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getVisas() {
      setLoadingVisas(true);

      try {
        const response = await GET_VISAS_USER();

        const visasMapped = response.data.map((visa) => {
          const result = {
            ...visa,
            nameApplicant: visa?.name || "Não informado",
            inProgress: !!(visa.status === "PROGRESS"),
          };

          return result;
        });

        setLoadingVisas(false);
        setVisas(visasMapped);
      } catch (error) {
        console.log("ERROR ", error);
        setSnackbar({
          open: true,
          message: "Erro ao carregar vistos.",
          severity: "error",
        });
        setLoadingVisas(false);
      }
    }
    getVisas();
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth="md">
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6">
                Olá, <strong>{user?.name}</strong>!
              </Typography>
              <Typography variant="subtitle1">
                Bem-vindo à plataforma da YouVisa
              </Typography>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button
                  onClick={startForm}
                  disabled={loading}
                  variant="contained"
                  color="primary"
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    "iniciar ficha de visto"
                  )}
                </Button>
              </Box>
            </CardContent>
          </Grid>
        </Grid>

        <Box mt={4} />

        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6">Solicitações de vistos</Typography>
              <Typography variant="subtitle1">
                Suas solicitações iniciadas aparecerão aqui
              </Typography>
              <Box mb={2} />
              <DataGrid
                rows={visas}
                columns={columns}
                pageSize={10}
                loading={loadingVisas}
                autoHeight
                getRowId={(row) => row._id}
                disableSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                localeText={{
                  footerTotalVisibleRows: (visibleCount, totalCount) =>
                    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                  noRowsLabel: "Nenhum registro encontrado",
                }}
              />
            </CardContent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
