import { Card } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const CardContent = withStyles({
  root: {
    boxShadow: "10px 0px 40px rgba(0, 0, 0, 0.1)",
    padding: 20,
    borderRadius: 24,
  },
})(Card);
