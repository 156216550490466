import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/auth";

const Router = ({ path, component: Component, isPrivate, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (isPrivate && !isAuthenticated()) {
    return <Redirect to="/auth/signin" />;
  }

  if (isAuthenticated() && path === "/auth/signin") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default Router;
