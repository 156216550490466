import { KEY_TOKEN } from "../config/constants";
import api from "../services/api";

export const decodeJWT = (token) => {
  const base64Payload = token?.split(".")[1];
  const payload = Buffer.from(base64Payload, "base64");

  return JSON.parse(payload.toString());
};

export const isValidToken = (token) => {
  if (!token) {
    return false;
  }

  const payload = token?.split(".")[1];
  const decoded = JSON.parse(window.atob(payload));
  const now = new Date();
  const expires = new Date(decoded.exp * 1000);

  return now < expires;
};

export const setSession = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem(KEY_TOKEN, accessToken);

    api.visaForm.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    window.localStorage.removeItem(KEY_TOKEN);
    delete api.visaForm.defaults.headers.common.Authorization;
  }
};
