import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Box, Container, Grid } from "@material-ui/core";

import Header from "../../components/Header";

export default function SkeletonLoading() {
  return (
    <>
      <Header />

      <Container maxWidth="sm">
        <Grid item>
          <Box mt={16}>
            <Skeleton variant="text" width="100%" height={10} />
          </Box>

          <Box mt={4}>
            <Skeleton variant="text" width="80%" height={40} />
          </Box>

          <Skeleton
            variant="rect"
            width={"100%"}
            height={56}
            style={{ marginTop: 14, marginBottom: 10 }}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={56}
            style={{ marginBottom: 10 }}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={56}
            style={{ marginBottom: 10 }}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={56}
            style={{ marginBottom: 10 }}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={56}
            style={{ marginBottom: 10 }}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={56}
            style={{ marginBottom: 10 }}
          />
        </Grid>
      </Container>
    </>
  );
}
