import { useMemo, useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

import { useFormCurrentPage } from "../contexts/formCurrentPage";

export default function Input({ questionIndex, onSaveAnswer }) {
  let maxLength = 255;
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (formCurrentPage.questions[questionIndex]?.answer) {
      const value = formCurrentPage?.questions[questionIndex]?.answer[0].input;

      setFieldValue(value);
    }

    return () => {
      setFieldValue("");
    };
  }, [formCurrentPage.questions, questionIndex]);

  // function inputIsValid(value) {
  //   console.log("VALUE ", value);

  //   switch (formCurrentPage?.questions[questionIndex]?.category) {
  //     case "CEP":
  //       return /^\d{5}-\d{3}$/.test(value);
  //     default:
  //       return true;
  //   }
  // }

  const isMultiple = useMemo(() => {
    return !!formCurrentPage.multiple;
  }, [formCurrentPage]);

  function formatarCep(cep) {
    if (cep) {
      maxLength = 9;
      return cep.replace(/(\d{5})(\d{3})/g, "$1-$2");
    }
  }

  function formatInput(value) {
    switch (formCurrentPage?.questions[questionIndex]?.category) {
      case "CEP":
        return formatarCep(value);
      default:
        return value;
    }
  }

  function handleNewValue(oldValue, value) {
    const newQuestions = [
      ...oldValue.questions.slice(0, questionIndex),
      {
        ...oldValue.questions[questionIndex],
        answer: [
          { input: value },
          // { input: e.target.value }
        ],
      },
      ...oldValue.questions.slice(questionIndex + 1),
    ];

    let newValue = {
      ...oldValue,
      questions: newQuestions,
    };

    if (isMultiple) {
      let oldAnswers = oldValue.answers || [];

      if (!oldValue.answers) return newValue;

      let questionPositionIndex;

      for (let i = 0; i < oldAnswers.length; i++) {
        for (let a = 0; a < oldAnswers[i].length; a++) {
          const currentAnswer = oldAnswers[i][a];

          if (
            currentAnswer.question_id ===
            formCurrentPage?.questions[questionIndex]?._id
          ) {
            questionPositionIndex = i;
          }
        }
      }

      newValue.answers[questionPositionIndex].push({
        question_id: formCurrentPage?.questions[questionIndex]?._id,
        answer: [
          {
            input: value,
          },
        ],
      });
    }

    return newValue;
  }

  function onChange(e) {
    setFieldValue(() => formatInput(e.target?.value));
  }

  return (
    <TextField
      id={formCurrentPage?.questions[questionIndex]?.text}
      label={formCurrentPage?.questions[questionIndex]?.text}
      onBlur={() => {
        let newValue;

        if (fieldValue !== "") {
          setFormCurrentPage(
            (oldValue) => (newValue = handleNewValue(oldValue, fieldValue))
          );
        }

        if (!newValue) return;

        onSaveAnswer(newValue.questions[questionIndex], questionIndex);
      }}
      variant="outlined"
      fullWidth
      value={fieldValue}
      onChange={onChange}
      inputProps={{
        maxLength: maxLength,
        id: `${formCurrentPage?.questions[questionIndex]?.text}_${questionIndex}`,
      }}
    />
  );
}
