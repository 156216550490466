import { TextField } from "@material-ui/core";
import { useFormCurrentPage } from "../../contexts/formCurrentPage";

export default function CpfInput({ questionIndex, onSaveAnswer }) {
  const maxLength = 14;
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  function inputIsValid(value) {
    return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value);
  }

  function formatCpf(cpf) {
    return cpf
      ?.replace(/\D/g, "")
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  function validInput(value) {
    setFormCurrentPage({
      ...formCurrentPage,
      errorInputMessage: null,
    });

    if (!inputIsValid(value)) {
      return setFormCurrentPage({
        ...formCurrentPage,
        errorInputMessage: "Insira um CPF válido.",
      });
    }

    onSaveAnswer(formCurrentPage?.questions[questionIndex], questionIndex);
  }

  function onChange(e) {
    setFormCurrentPage((oldValue) => {
      let newQuestions = [
        ...oldValue.questions.slice(0, questionIndex),
        {
          ...oldValue.questions[questionIndex],
          answer: [
            { input: formatCpf(e.target?.value) },
            // { input: e.target.value }
          ],
        },
        ...oldValue.questions.slice(questionIndex + 1),
      ];

      let newValue = {
        ...oldValue,
        questions: newQuestions,
      };

      return newValue;
    });
  }

  return (
    <TextField
      id="phone"
      label={formCurrentPage?.questions[questionIndex]?.text}
      onBlur={() =>
        validInput(
          formCurrentPage?.questions[questionIndex]?.answer &&
            formCurrentPage?.questions[questionIndex]?.answer[0]?.input
        )
      }
      variant="outlined"
      fullWidth
      value={
        formCurrentPage?.questions[questionIndex]?.answer &&
        formCurrentPage?.questions[questionIndex]?.answer[0]?.input
      }
      onChange={onChange}
      inputProps={{ maxLength: maxLength }}
    />
  );
}
