import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import logo from "../assets/youvisa-white.png";
import theme from "../styles/theme";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
        backgroundImage: `linear-gradient(${theme.primaryLight}, #187441)`,
      }}
    >
      <img
        src={logo}
        alt="logo-yv"
        style={{
          position: "absolute",
          top: "12%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />

      <Box mt={5} />

      <Typography
        variant="h5"
        style={{
          color: theme.lightGray,
        }}
      >
        <strong>Página não encontrada!</strong>
      </Typography>

      <Box mt={5} />
      <Box display="flex" flexDirection="row" p={1}>
        <Button
          variant="contained"
          color="default"
          onClick={() => (window.location.href = "https://youvisa.com.br")}
        >
          Página principal
        </Button>
        <Box mr={2} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = "/auth/signin")}
        >
          Início
        </Button>
      </Box>
    </div>
  );
}
