import { createTheme } from "@material-ui/core";

import Epilogue from "../assets/font/Epilogue-Regular.ttf";
import theme from "./theme";

const epilogue = {
  fontFamily: "Epilogue",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Epilogue'),
    local('Epilogue-Regular'),
    url(${Epilogue}) format('ttf')
  `,
};

export const themeApplication = createTheme({
  palette: {
    primary: {
      main: theme.primary,
    },
  },
  typography: {
    fontFamily: '"Epilogue", Roboto, sans-serif',
    subtitle1: {
      color: theme.gray,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [epilogue],
      },
    },
    MuiButton: {
      root: {
        height: 40,
      },
    },
  },
});
