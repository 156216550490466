import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Eye from "@material-ui/icons/Visibility";
import EyeOff from "@material-ui/icons/VisibilityOff";

import logo from "../../../assets/youvisa-white.png";
// import GoogleIcon from "../../../assets/icons/google.svg";
// import FacebookIcon from "../../../assets/icons/facebook.svg";

import { Container, Form } from "./styles";
import { useAuth } from "../../../contexts/auth";
import ConfirmationCode from "../../../components/Modal/ConfirmationCode";

export default function SignIn({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    login,
    sendCode,
    //  forgotPassword
  } = useAuth();

  // const redirect_uri = "https://app-youvisa.netlify.app";

  function formIsValid() {
    if (email === "") {
      setError("Email é obrigatório");
      return false;
    } else if (password === "") {
      setError("Senha é obrigatório");
      return false;
    }
    return true;
  }

  async function signin(e) {
    e.preventDefault();
    setError(null);
    if (formIsValid()) {
      setLoading(true);
      try {
        await login(email, password);
        setLoading(false);

        return history.push("/dashboard");
      } catch (error) {
        setLoading(false);
        console.log("error signing in", error);
        setLoading(false);

        if (error.code === "UserNotConfirmedException") {
          sendCode(email);
          setOpenModal(true);
          return setError("Confirme seu e-mail, para continuar.");
        }
        setError("Verifique seu email e senha");
      }
    }
  }

  // async function handleForgotPassword() {
  //   setLoading(true);
  //   try {
  //     await forgotPassword(email);
  //     setOpenModal(true);
  //   } catch (error) {
  //     console.log("error ", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Container>
      <section>
        <img src={logo} alt="logo-yv" />

        <div className="welcome-container">
          <Typography variant="h5" style={{ color: "white" }}>
            <strong>Olá!</strong>
          </Typography>
          <Typography variant="body1" style={{ color: "white" }}>
            Somos uma plataforma que vai ajudar você a aplicar seu visto!
          </Typography>
        </div>
      </section>

      <section>
        <Form onSubmit={signin}>
          <div
            style={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <Typography variant="subtitle1">
              Não consegue entrar?{" "}
              <Link
                href="https://api.whatsapp.com/send?l=pt_BR&phone=551142002082&text=Ol%C3%A1,%20tive%20um%20problema%20para%20logar%20na%20minha%20conta.%20Podem%20me%20ajudar?"
                target={"_blank"}
              >
                <strong>Fale conosco</strong>
              </Link>
            </Typography>
          </div>
          <div className="login-text">
            <Typography variant="h5">Login</Typography>
            <Typography variant="subtitle1">
              Digite seu e-mail e senha para entrar
            </Typography>
          </div>
          <TextField
            variant="outlined"
            label="E-mail"
            fullWidth
            style={{ marginBottom: 12 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Senha"
            fullWidth
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <Eye /> : <EyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box mt={2}>
            <Typography variant="subtitle2">{error}</Typography>
          </Box>

          <div className="sign-container">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? "Carregando..." : "Entrar"}
            </Button>
            <Button
              color="primary"
              fullWidth
              onClick={() => history.push("/auth/signup")}
            >
              Criar conta
            </Button>
            <Button
              color="primary"
              fullWidth
              onClick={() => history.push("/auth/forgot-password")}
            >
              Esqueci minha senha
            </Button>
          </div>

          {/* <div className="sign-social-auth-text">
            <hr />
            <Typography variant="subtitle1">ou entre com</Typography>
            <hr />
          </div>

          <div className="sign-social-auth">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                const url = `${process.env.REACT_APP_REDIRECT_AUTH_URL}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirect_uri}&response_type=TOKEN&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid`;
                return (window.location.href = url);
              }}
              startIcon={
                <img src={GoogleIcon} alt="google-icon" style={{ width: 20 }} />
              }
            >
              GOOGLE
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                return (window.location.href = `${process.env.REACT_APP_REDIRECT_AUTH_URL}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${redirect_uri}&response_type=TOKEN&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid`);
              }}
              startIcon={
                <img
                  src={FacebookIcon}
                  alt="facebook-icon"
                  style={{ width: 20 }}
                />
              }
            >
              Facebook
            </Button>
          </div> */}
        </Form>
      </section>

      <ConfirmationCode
        openModal={openModal}
        history={history}
        username={email}
      />
    </Container>
  );
}
