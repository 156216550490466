import api from "./api";

export const GET_VISA_FORM = (formId) => {
  return api.visaForm.get(`/api/visa-forms/v1/${formId}`);
};

export const START_VISA_FORM = (body) => {
  return api.visaForm.post("/api/visa-forms/v1/start", body);
};

export const GET_VISAS_USER = () => {
  return api.visaForm.get("/api/visa-forms/v1");
};

export const SAVE_SIMPLE_ANSWER = (body) => {
  return api.visaForm.post("/api/visa-forms/v1/answer/simple", body);
};

export const SAVE_MULTIPLE_ANSWER = (body) => {
  return api.visaForm.post("/api/visa-forms/v1/answer/multiple", body);
};

export const FINISH_VISA_FORM = (body) => {
  return api.visaForm.post("/api/visa-forms/v1/finish", body);
};
