import { memo } from "react";
import { Grid, LinearProgress } from "@material-ui/core";

function ProgressBar({ value }) {
  return (
    <Grid container style={{ marginBottom: 40 }}>
      <LinearProgress
        variant="determinate"
        style={{ width: "100%", height: 6 }}
        value={value}
      />
    </Grid>
  );
}

export default memo(ProgressBar, (prev, next) => prev.value === next.value);
