import { Link } from "react-router-dom";
import styled from "styled-components";

import theme from "../../styles/theme";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${theme.lightGray};

  @media (max-width: 960px) {
    padding: 10px;
  }
`;

export const Logo = styled.img`
  max-width: 188px;
  height: auto;
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
`;

export const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  padding: 0 10px;
  transition: opacity 0.2s;
  border-bottom: 2px solid
    ${({ pathactived }) =>
      pathactived === "true" ? theme.primaryLight : "transparent"};

  h6 {
    color: ${({ pathactived }) => pathactived === "true" && theme.primary};
    font-weight: ${({ pathactived }) => pathactived === "true" && "bold"};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 188px;
`;

export const ContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 5px;

  p {
    font-weight: bold;
  }

  button {
    height: auto;
    padding: 0;
  }
`;
