import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
// import { useEffect } from "react";
import { useFormCurrentPage } from "../contexts/formCurrentPage";

export default function RadioButton({ questionIndex, onSaveAnswer }) {
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  function handleNewValue(oldValue, value) {
    let newValue;
    let newQuestions = [
      ...oldValue.questions.slice(0, questionIndex),
      {
        ...oldValue.questions[questionIndex],
        answer: [{ option_id: value }],
      },
      ...oldValue.questions.slice(questionIndex + 1),
    ];

    let startIndex = 0;
    let endIndex = oldValue.questions.length - 1;

    if (oldValue.multiple) {
      let questionsArray = oldValue.questions;
      let chunkSize = getMultipleQuestionsChunkSize(questionsArray);

      let chunkChangedIndex = getChunkChangedIndex(chunkSize);
      startIndex = getSubArrayStartIndex(chunkSize, chunkChangedIndex);
      endIndex = getSubArrayEndIndex(startIndex, chunkSize);
    }

    const optionSelected = oldValue.questions[questionIndex].options.find(
      (option) => option._id === value
    );

    toogleTrigger(oldValue, optionSelected, startIndex, endIndex);

    newValue = {
      ...oldValue,
      next: Number(optionSelected?.next_page) || oldValue.next,
      questions: newQuestions,
    };

    return newValue;
  }

  function getChunkChangedIndex(chunkSize) {
    return Math.floor(questionIndex / chunkSize);
  }

  function getSubArrayStartIndex(chunkSize, index) {
    return chunkSize * index;
  }

  function getSubArrayEndIndex(startIndex, chunkSize) {
    return startIndex + chunkSize - 1;
  }

  function getMultipleQuestionsChunkSize(questionArray) {
    let firstArrayElementId = questionArray[0]._id;
    let firtsElementCount = formCurrentPage.questions.filter(
      (question) => question._id == firstArrayElementId
    ).length;
    return questionArray.length / firtsElementCount;
  }

  function handleClick(e) {
    let newValue;
    setFormCurrentPage(
      (oldValue) => (newValue = handleNewValue(oldValue, e.target.value))
    );
    onSaveAnswer(newValue.questions[questionIndex], questionIndex);
  }

  function getPreviousAnswer(oldValue) {
    return oldValue?.questions[questionIndex]?.answer &&
      oldValue?.questions[questionIndex]?.answer[0]?.option_id;
  }

  function getPreviousOptionSelected(oldValue, previousAnswer) {
    return oldValue?.questions[
      questionIndex
    ].options.find((option) => option._id === previousAnswer);
  }

  function toogleTrigger(oldValue, optionSelected, startIndex, endIndex) {

    const previousAnswer = getPreviousAnswer(oldValue);
    const previousOptionSelected = getPreviousOptionSelected(oldValue, previousAnswer)

    if (optionSelected?.trigger?.length > 0) {

      for (let i = startIndex; i <= endIndex; i++) {
        let quest = oldValue.questions[i];
        optionSelected.trigger?.forEach((triggerSelectedOption) => {
          if (quest._id === triggerSelectedOption) {
            quest.required = true;
            quest.hidden = false;
          }
          previousOptionSelected?.trigger?.forEach((triggerPreviousOption) => {
            if (quest._id === triggerPreviousOption) {
              quest.required = false;
              quest.hidden = true;
            }
          });
        });
      }
    } else if (previousOptionSelected?.trigger?.length > 0) {

      for (let i = startIndex; i <= endIndex; i++) {
        let quest = oldValue.questions[i];
        previousOptionSelected?.trigger?.forEach((trigger) => {
          // console.log("triggers previousOptionSelected => ", trigger);
          if (quest._id === trigger) {
            quest.required = false;
            quest.hidden = true;
          }
        });
      }
    }
  }

  // useEffect(() => {
  //   let value =
  //     formCurrentPage.questions[questionIndex].answer &&
  //     formCurrentPage.questions[questionIndex].answer[0].option_id;

  //   setFormCurrentPage((oldValue) => handleNewValue(oldValue, value));
  // }, []);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {formCurrentPage?.questions[questionIndex]?.text}
      </FormLabel>
      <RadioGroup
        row
        aria-label="gender"
        name="gender1"
        value={
          formCurrentPage?.questions[questionIndex]?.answer &&
          formCurrentPage?.questions[questionIndex]?.answer[0][
          Object.keys(formCurrentPage?.questions[questionIndex]?.answer[0])[0]
          ]
        }
        onChange={(e) => handleClick(e)}
      >
        {formCurrentPage?.questions[questionIndex]?.options?.map((option) => (
          <FormControlLabel
            key={String(option._id)}
            value={option._id}
            control={<Radio color="primary" />}
            label={option.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
