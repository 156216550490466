import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";

import logo from "../../../assets/youvisa-white.png";

import { Container, Form } from "./styles";
import { useAuth } from "../../../contexts/auth";
import ResetPassword from "../../../components/Modal/ResetPassword";

export default function ForgotPassword({ history }) {
  const [email, setEmail] = useState("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { forgotPassword } = useAuth();

  function formIsValid() {
    if (email === "") {
      setError("Email é obrigatório");
      return false;
    }
    return true;
  }

  async function handleForgotPassword(e) {
    e.preventDefault();
    if (formIsValid()) {
      setLoading(true);
      try {
        await forgotPassword(email);
        setOpenModal(true);
      } catch (error) {
        setError("Erro ao enviar o email, tente novamente mais tarde.");
        console.log("error ", error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Container>
      <section>
        <img src={logo} alt="logo-yv" />

        <div className="welcome-container">
          <Typography variant="h5" style={{ color: "white" }}>
            <strong>Olá!</strong>
          </Typography>
          <Typography variant="body1" style={{ color: "white" }}>
            Somos uma plataforma que vai ajudar você a aplicar seu visto!
          </Typography>
        </div>
      </section>

      <section>
        <Form onSubmit={handleForgotPassword}>
          <div className="login-text">
            <Typography variant="h5">Esqueci minha senha</Typography>
            <Typography variant="subtitle1">
              Digite seu e-mail que te enviaremos um e-mail para recuperar a
              senha
            </Typography>
          </div>
          <TextField
            variant="outlined"
            label="E-mail"
            fullWidth
            style={{ marginBottom: 12 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Box mt={2}>
            <Typography variant="subtitle2">{error}</Typography>
          </Box>

          <div className="sign-container">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? "Carregando..." : "Enviar"}
            </Button>
          </div>
        </Form>
      </section>

      <ResetPassword
        openModal={openModal}
        history={history}
        username={email}
        callback={() => setOpenModal(false)}
      />
    </Container>
  );
}
