import React from "react";
import { TextField, TextareaAutosize } from "@material-ui/core";

import { useFormCurrentPage } from "../contexts/formCurrentPage";
// import api from '../services/api';

import RadioButton from "./RadioButton";
import Dropdown from "./Dropdown";
import DatePicker from "./DatePicker";
import Input from "./Input";
import Enum from "./Enum";
import FileInput from "./FileInput";
import PhoneInput from "./Input/Phone";
import MailInput from "./Input/Mail";
import CpfInput from "./Input/Cpf";
import AutocompleteInput from "./Input/Autocomplete";

export default function RenderForm({ index, onSaveAnswer }) {
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  function genericRender() {
    // console.log("CATEGORY ", formCurrentPage?.questions[index]?.category);
    switch (formCurrentPage?.questions[index]?.category) {
      case "CALENDAR":
        return <DatePicker questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "TELEPHONE":
        return <PhoneInput questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "EMAIL":
        return <MailInput questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "CPF":
        return <CpfInput questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "AUTOCOMPLETED":
      case "OPEN":
      case "NUMBER":
      case "MONEY":
        return <Input questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "RADIO":
        return (
          <RadioButton questionIndex={index} onSaveAnswer={onSaveAnswer} />
        );
      case "DROPDOWN":
        return <Dropdown questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "DOCUMENT":
        return <FileInput questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "ENUM":
        return <Enum questionIndex={index} onSaveAnswer={onSaveAnswer} />;
      case "PLAINTEXT":
        return (
          <TextareaAutosize
            value={formCurrentPage?.questions[index]?.text}
            maxRows='40'
            style={{ width: '100%', resize: 'none', textAlign: "justify" }}
          />
        );
      default:
        return (
          <TextField
            label={formCurrentPage?.questions[index]?.text}
            onBlur={() => {
              onSaveAnswer(formCurrentPage?.questions[index], index);
            }}
            variant="outlined"
            fullWidth
            value={
              (formCurrentPage?.questions[index]?.answer &&
                formCurrentPage?.questions[index]?.answer[0]?.input) ||
              ""
            }
            onChange={(value) => {
              // console.log('OPEN', value.target.value);
              setFormCurrentPage((oldValue) => {
                let newQuestions = [
                  ...oldValue.questions.slice(0, index),
                  {
                    ...oldValue.questions[index],
                    answer: [{ input: value.target.value }],
                  },
                  ...oldValue.questions.slice(index + 1),
                ];

                let newValue = {
                  ...oldValue,
                  questions: newQuestions,
                };

                return newValue;
              });
            }}
          />
        );
    }
  }

  return genericRender();
}
