import { Button, Grid, Typography } from "@material-ui/core";

import aventureiro from "../../../assets/img/aventureiro.png";
import consul from "../../../assets/img/consul.png";
import diplomata from "../../../assets/img/diplomata.png";
import embaixador from "../../../assets/img/embaixador.png";
import explorador from "../../../assets/img/explorador.png";
import { WHATSAPP_NUMBER } from "../../../config/constants";
import { Image } from "./styles";

function ResultProfileTeste({ result }) {
  function renderImage() {
    switch (result?.title) {
      case "Aventureiro":
        return <Image src={aventureiro} />;
      case "Consul":
        return <Image src={consul} />;
      case "Diplomata":
        return <Image src={diplomata} />;
      case "Embaixador":
        return <Image src={embaixador} />;
      case "Explorador":
        return <Image src={explorador} />;
      default:
        return <Image src={aventureiro} />;
    }
  }

  return (
    <div>
      <Grid
        container
        spacing={6}
        alignItems="center"
        style={{ marginTop: 20, flexWrap: "wrap-reverse" }}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h6" align="left">
            Seu perfil é:
          </Typography>
          <Typography
            variant="h4"
            align="left"
            style={{ fontWeight: "bold", marginTop: 30, marginBottom: 20 }}
          >
            {result?.title}
          </Typography>
          <Typography variant="body1" align="left">
            {result?.text}
          </Typography>

          <div style={{ margin: "24px 0" }}>
            {result?.attention_points?.map((point) => (
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bold" }}
              >
                {point}
              </Typography>
            ))}
          </div>

          <Typography variant="body1" align="left">
            {result?.call_to_action}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {renderImage()}
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 50,
          flexWrap: "wrap",
          marginBottom: 20,
        }}
      >
        <Button
          variant="contained"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?l=pt_BR&phone=${WHATSAPP_NUMBER}&text=Olá,%20tenho%20interesse%20em%20mais%20informações%20sobre%20vistos`,
              "_blank"
            )
          }
          style={{ margin: 12 }}
        >
          Fale com um especialista
        </Button>
        {/* <Button variant="contained" color="primary" style={{ margin: 12 }}>
          Começe seu visto agora
        </Button> */}
      </div>
    </div>
  );
}

export default ResultProfileTeste;
