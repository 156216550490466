import api from "./api";

export const GET_CITIES = () => {
  return api.choiceLink.get("/choice-options/bornplace");
};

export const GET_CHOICE_OPTION = (choice, options) => {
  return api.choiceLink.get(`/api/v1/choice-options/${choice}`, {
    ...options
  });
};

// Familyralated
// Relationship
// Socialmedia
// Finance
// Workcontract
// Visatype
// Level
// Proficiencytest
// Edustatus
// Education
