import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import congrats from "../../../assets/img/congrats.png";
import { WHATSAPP_NUMBER } from "../../../config/constants";

export default function ResultVisaForm({ history }) {
  return (
    <Container maxWidth="md">
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <Typography variant="h5">
              <b>Ficha preenchida com sucesso!</b>
            </Typography>
            <img
              src={congrats}
              alt="Finalizado"
              height={318}
              width={404}
              style={{ marginTop: 20 }}
            />
            <Box mt={6} display="flex" justifyContent="center" flexWrap="wrap">
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/dashboard")}
                style={{ margin: 10 }}
              >
                Meus vistos
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?l=pt_BR&phone=${WHATSAPP_NUMBER}&text=Olá,%20finalizei%20a%20ficha%20de%20visto`,
                    "_blank"
                  )
                }
                style={{ margin: 10 }}
              >
                Fale com um especialista
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
