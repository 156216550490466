import React, { createContext, useContext } from "react";

const FormCurrentPageContext = createContext();

function FormCurrentPageProvider({ children }) {
  const [formCurrentPage, setFormCurrentPage] = React.useState(null);
  const [isDisabledButton, setIsDisabledButton] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  function duplicateMultipleQuestion() {
    const newFormCurrentPage = JSON.parse(JSON.stringify(formCurrentPage));

    // duplica a partir da segunda vez
    if (newFormCurrentPage?.originalQuestion) {
      // Clone de objeto sem as referencias
      let newQuestions = JSON.parse(
        JSON.stringify(newFormCurrentPage.originalQuestion)
      );

      if (newFormCurrentPage.answers) {
        const objectToDuplicate = [];

        for (let i = 0; i < newFormCurrentPage.answers.length; i++) {
          for (let a = 0; a < newQuestions.length; a++) {
            const currentAnswer = newQuestions[i];

            if (!currentAnswer) break;

            objectToDuplicate.push(currentAnswer);

            break;
          }
        }

        objectToDuplicate.forEach((question) => delete question.answer);

        newFormCurrentPage.questions = [
          ...newFormCurrentPage.questions,
          ...objectToDuplicate,
        ];
      } else {
        newQuestions?.forEach((question) => delete question.answer);

        newFormCurrentPage.questions = [
          ...newFormCurrentPage.questions,
          ...newQuestions,
        ];
      }

      setFormCurrentPage(newFormCurrentPage);

      return;
    }

    // duplica a primeira vez
    newFormCurrentPage.originalQuestion = newFormCurrentPage.questions;
    let newQuestions = JSON.parse(
      JSON.stringify(newFormCurrentPage.originalQuestion)
    );

    newQuestions?.forEach((question) => delete question.answer);

    newFormCurrentPage.questions = [
      ...newFormCurrentPage.questions,
      ...newQuestions,
    ];

    setFormCurrentPage(newFormCurrentPage);
  }

  return (
    <FormCurrentPageContext.Provider
      value={{
        formCurrentPage,
        setFormCurrentPage,
        duplicateMultipleQuestion,
        isDisabledButton,
        setIsDisabledButton,
        isSaving,
        setIsSaving,
      }}
    >
      {children}
    </FormCurrentPageContext.Provider>
  );
}

function useFormCurrentPage() {
  const context = useContext(FormCurrentPageContext);

  if (context === undefined) {
    throw new Error(
      "useFormCurrentPage must be used within a FormCurrentPageProvider"
    );
  }

  return context;
}

export { FormCurrentPageProvider, useFormCurrentPage };

