import { TextField } from "@material-ui/core";
import { useFormCurrentPage } from "../../contexts/formCurrentPage";

export default function PhoneInput({ questionIndex, onSaveAnswer }) {
  const maxLength = 15;
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  function inputIsValid(value) {
    if (!value?.trim()) {
      return false;
    }

    // return /(\d{2})(\d{4,5})(\d{4})/.test(value?.replace(/\D/g, ""));
    return true;
  }

  // function formatPhone(telefone) {
  //   return telefone
  //     ?.replace(/\D/g, "")
  //     .replace(/(\d{2})(\d{4,5})(\d{4})/g, "($1) $2-$3");
  // }

  function validInput(value) {
    setFormCurrentPage({
      ...formCurrentPage,
      errorInputMessage: null,
    });

    if (!inputIsValid(value)) {
      return setFormCurrentPage({
        ...formCurrentPage,
        errorInputMessage: "Insira um telefone válido.",
      });
    }

    onSaveAnswer(formCurrentPage?.questions[questionIndex], questionIndex);
  }

  function onChange(e) {
    setFormCurrentPage((oldValue) => {
      let newQuestions = [
        ...oldValue.questions.slice(0, questionIndex),
        {
          ...oldValue.questions[questionIndex],
          answer: [{ input: e.target?.value?.replace(/\D/g, "") }],
          // answer: [{ input: formatPhone(e.target?.value) }],
        },
        ...oldValue.questions.slice(questionIndex + 1),
      ];

      let newValue = {
        ...oldValue,
        questions: newQuestions,
      };

      return newValue;
    });
  }

  return (
    <TextField
      id="phone"
      label={formCurrentPage?.questions[questionIndex]?.text}
      onBlur={() =>
        validInput(
          formCurrentPage?.questions[questionIndex]?.answer &&
            formCurrentPage?.questions[questionIndex]?.answer[0]?.input
        )
      }
      variant="outlined"
      fullWidth
      value={
        formCurrentPage?.questions[questionIndex]?.answer &&
        formCurrentPage?.questions[questionIndex]?.answer[0]?.input
      }
      onChange={onChange}
      inputProps={{ maxLength: maxLength }}
    />
  );
}
