import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const TitleQuestion = styled(Typography).attrs(props => ({
  variante: 'overline',
}))`
  font-weight: bold!important;
  font-size: 1.2rem!important;
  text-align: justify;
`
