import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";

import ConfirmationCode from "../../../components/Modal/ConfirmationCode";
import logo from "../../../assets/youvisa-white.png";
import StringUtil from "../../../utils/stringUtil";
import { useAuth } from "../../../contexts/auth";
import { Container, Form } from "./styles";

export default function SignUp({ history }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { register } = useAuth();

  function validateForm() {
    if (name.length === 0) {
      setError("Nome é obrigatório");
      return false;
    }
    if (email.length === 0) {
      setError("E-mail é obrigatório");
      return false;
    }
    if (password.length === 0) {
      setError("Senha é obrigatório");
      return false;
    }
    if (confirmPassword.length === 0) {
      setError("Confirmação de senha é obrigatório");
      return false;
    }
    if (password !== confirmPassword) {
      setError("Senhas não conferem");
      return false;
    }
    if (!new StringUtil().senhaValida(password)) {
      setError(
        "Senha inválida, sua senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial"
      );
      return false;
    }

    return true;
  }

  async function signUp() {
    setError(null);
    if (!validateForm()) return;

    setLoading(true);

    try {
      await register(email, password, name);

      setLoading(false);
      setOpenModal(true);
    } catch (error) {
      setLoading(false);

      if (error.code === "UsernameExistsException") {
        return setError("Usuário já existe");
      }

      setError("Erro ao enviar cadastro! Tente novamente mais tarde.");

      console.log("error signing up:", error);
    }
  }

  return (
    <Container>
      <section>
        <img src={logo} alt="logo" />
        <div className="welcome-container">
          <Typography variant="h5" style={{ color: "white" }}>
            <strong> Olá! </strong>
          </Typography>
          <Typography variant="body1" style={{ color: "white" }}>
            Somos uma plataforma que vai ajudar você a aplicar seu visto!
          </Typography>
        </div>
      </section>

      <section>
        <Form>
          {/* <div>
            <Typography variant="subtitle2">
              Dificuldade para criar sua conta?{" "}
            </Typography>
            <Button id="fale-conosco" variant="text" fullWidth>
              Fale conosco!
            </Button>
          </div> */}
          <div className="login-text">
            <Typography variant="h5"> Crie sua conta YouVisa </Typography>
          </div>
          <TextField
            variant="outlined"
            label="Nome"
            fullWidth
            style={{ marginBottom: 12 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="E-mail"
            fullWidth
            style={{ marginBottom: 12 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="container-password">
            <TextField
              type="password"
              variant="outlined"
              label="Senha"
              style={{ marginRight: 12 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              type="password"
              variant="outlined"
              label="Confirmar senha"
              style={{ marginBottom: 12 }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          {error && (
            <Box mt={2}>
              <Typography
                style={{ color: "#F45", fontWeight: "bold" }}
                variant="subtitle2"
              >
                {error}
              </Typography>
            </Box>
          )}

          <div className="sign-container">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              // onClick={() => setOpenModal(true)}
              onClick={signUp}
              disabled={loading}
            >
              {loading ? <CircularProgress size={30} /> : "Criar conta"}
            </Button>
          </div>

          {/* <div className="sign-social-auth-text">
            <hr style={{ flex: 1 }} />
            <Typography variant="subtitle1"> ou entre com </Typography>
            <hr style={{ flex: 1 }} />
          </div>
          <div className="sign-social-auth">
            <Button variant="outlined" fullWidth>
              Google
            </Button>
            <Button variant="outlined" fullWidth>
              Facebook
            </Button>
          </div> */}

          <div className="account-signin">
            <Typography variant="subtitle2">
              Já tem uma conta?
              <span
                className="action-signin"
                onClick={() => history.push("/auth/signin")}
              >
                Entrar
              </span>
            </Typography>
          </div>
        </Form>
      </section>

      <ConfirmationCode
        openModal={openModal}
        username={email}
        history={history}
      />
    </Container>
  );
}
