import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from 'axios';
import { useEffect, useMemo, useState } from "react";
import { useFormCurrentPage } from "../contexts/formCurrentPage";
import { GET_CHOICE_OPTION } from "../services/ChoiceOptionsService";


export default function Dropdown({ questionIndex, onSaveAnswer }) {
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  const initialOptions = formCurrentPage?.questions[questionIndex]?.options;

  const [options, setOptions] = useState(initialOptions);
  const [isChoiceLink, setIsChoiceLink] = useState(false);

  const isMultiple = useMemo(() => {
    return !!formCurrentPage.multiple;
  }, [formCurrentPage]);

  const initialValue = useMemo(() => {
    const defaultValue = "Selecione";
    const answer = formCurrentPage.questions[questionIndex].answer
      ? formCurrentPage.questions[questionIndex].answer[0]
      : {};

    switch (true) {
      case Boolean(answer.option_id): {
        const questionOption = formCurrentPage.questions[questionIndex].options.find(
          (option) => answer.option_id === option._id
        );
        const value = questionOption ? questionOption.text : undefined;

        if (!value) return defaultValue;

        return value;
      }
      case Boolean(answer.input): {
        return answer.input;
      }
      default: {
        return defaultValue;
      }
    }
  }, [formCurrentPage.questions, questionIndex]);

  // const handleChangeOptions = useCallback(async () => {

  // }, [formCurrentPage?.questions, isMultiple, questionIndex]);

  // const valueInput = useMemo(() => {
  //   if (formCurrentPage?.answers) {
  //     // console.log("questions index ", questionIndex);
  //     // console.log("form ", formCurrentPage?.answers[questionIndex]);
  //     const answerByQuestion =
  //       formCurrentPage?.answers &&
  //       formCurrentPage?.answers[questionIndex]?.find((answer, index) => {
  //         console.log("answer in ", answer, index);
  //         return answer?.answer[index];
  //       });

  //     console.log("answerByQuestion", answerByQuestion);

  //     return (answerByQuestion && answerByQuestion?.answer[0]?.input) ?? "";
  //   }

  //   return (
  //     (formCurrentPage?.questions &&
  //       formCurrentPage?.questions[questionIndex]?.answer &&
  //       formCurrentPage?.questions[questionIndex]?.answer[0]?.option_id) ??
  //     ""
  //   );
  // }, [questionIndex]);

  const answerByQuestion =
    formCurrentPage?.answers &&
    formCurrentPage?.answers[questionIndex]?.find((answer, index) => {
      console.log("answer in ", answer, index);
      return answer?.answer[index];
    });
  // console.log(
  //   "answr ",
  //   (answerByQuestion && answerByQuestion?.answer[0]?.input) ?? ""
  // );

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getOptions = async () => {
      try {
        const choices_link =
          formCurrentPage?.questions[questionIndex]?.choices_link;

        const response = await GET_CHOICE_OPTION(choices_link, {
          cancelToken: source.token
        });

        setIsChoiceLink(true);
        setOptions(response.data);
      } catch (error) {
        console.log("error get options", error);
      }      
    }

    const hasChoicesLink = formCurrentPage?.questions[questionIndex].choices_link.length > 0;

    if (hasChoicesLink) {
      getOptions();
    } else {
      setOptions(formCurrentPage?.questions[questionIndex]?.options);
    }

    return () => {
      source.cancel('Request Aborted');
      setIsChoiceLink(false);
    };
  }, [formCurrentPage?.questions, isMultiple, questionIndex]);

  function toogleTrigger(oldValue, newOptionValue, optionSelected) {
    const previousAnswer =
      oldValue?.questions[questionIndex]?.answer &&
      oldValue?.questions[questionIndex]?.answer[0]?.option_id;

    // console.log("previousAnswer", previousAnswer);

    const previousOptionSelected = oldValue.questions[
      questionIndex
    ].options.find((option) => option._id === previousAnswer);

    // console.log("previousOptionSelected", previousOptionSelected);

    if (optionSelected?.trigger?.length > 0) {
      oldValue.questions.forEach((quest) => {
        optionSelected.trigger?.forEach((triggerSelectedOption) => {
          if (quest._id === triggerSelectedOption) {
            quest.hidden = false;
          }
          previousOptionSelected?.trigger?.forEach((triggerPreviousOption) => {
            if (quest._id === triggerPreviousOption) {
              quest.hidden = true;
            }
          });
        });
      });
    } else if (previousOptionSelected?.trigger?.length > 0) {
      oldValue.questions?.forEach((quest) => {
        previousOptionSelected?.trigger.forEach((trigger) => {
          // console.log("triggers previousOptionSelected => ", trigger);
          if (quest._id === trigger) {
            quest.hidden = true;
          }
        });
        // console.log("quest => ", quest);
      });
    }

    // console.log("oldValue => ", oldValue);
  }

  function handleNewValue(oldValue, question) {
    let newValue;
    const answer = {
      [isChoiceLink ? "input" : "option_id"]: isChoiceLink
        ? question.text
        : question._id,
    };

    let newQuestions = [
      ...oldValue.questions.slice(0, questionIndex),
      {
        ...oldValue.questions[questionIndex],
        answer: [answer],
      },
      ...oldValue.questions.slice(questionIndex + 1),
    ];

    const optionSelected = oldValue.questions[questionIndex].options.find(
      (option) => option._id === answer?.option_id
    );

    toogleTrigger(oldValue, answer?.option_id, optionSelected);

    newValue = {
      ...oldValue,
      questions: newQuestions,
      next: Number(optionSelected?.next_page) || oldValue.next,
    };

    if (isMultiple) {
      let oldAnswers = oldValue.answers || [];

      if (!oldValue.answers) {
        newValue.answer = [[{ input: question.text }]];

        return newValue;
      }

      let questionPositionIndex;

      for (let i = 0; i < oldAnswers.length; i++) {
        for (let a = 0; a < oldAnswers[i].length; a++) {
          const currentAnswer = oldAnswers[i][a];

          if (
            currentAnswer.question_id ===
            formCurrentPage?.questions[questionIndex]?._id
          ) {
            questionPositionIndex = i;
          }
        }
      }

      newValue.answers[questionPositionIndex].push({
        question_id: formCurrentPage?.questions[questionIndex]?._id,
        answer: [
          {
            input: question.text,
          },
        ],
      });
    }

    return newValue;
  }

  function handleClick(question) {
    let newValue;
    setFormCurrentPage(
      (oldValue) => (newValue = handleNewValue(oldValue, question))
    );
    onSaveAnswer(newValue.questions[questionIndex], questionIndex);
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={`dropdown-${questionIndex}`}>
        {formCurrentPage?.questions[questionIndex]?.text}
      </InputLabel>

      <Select
        labelId={`dropdown-${questionIndex}`}
        label={formCurrentPage?.questions[questionIndex]?.text}
        placeholder="Selecione"
        value={initialValue}
      >
        <MenuItem value={initialValue}>{initialValue}</MenuItem>
        {options
          .filter((question) => question.text !== initialValue)
          .map((question) => {
            return (
              <MenuItem
                key={question._id}
                value={isChoiceLink ? question.text : question._id}
                onClick={() => handleClick(question)}
              >
                {question.text}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
