import { Button } from "@material-ui/core";
import { useFormCurrentPage } from "../contexts/formCurrentPage";

export default function Enum({ questionIndex }) {
  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  function handleClick(e, option) {
    setFormCurrentPage((oldValue) => {
      let newQuestions = [
        ...oldValue.questions.slice(0, questionIndex),
        {
          ...oldValue.questions[questionIndex],
          answer: [
            { option_id: option._id },
            // { input: e.target.value }
          ],
        },
        ...oldValue.questions.slice(questionIndex + 1),
      ];

      let newValue = {
        ...oldValue,
        next: Number(option?.next_page) || oldValue.next,
        questions: newQuestions,
      };

      return newValue;
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {formCurrentPage.questions[questionIndex].options.map((option, index) => {
        const isSelected =
          formCurrentPage?.questions[questionIndex]?.answer &&
          formCurrentPage?.questions[questionIndex]?.answer[0]?.option_id ===
            option._id;

        return (
          <Button
            variant={isSelected ? "contained" : "outlined"}
            style={{
              marginBottom: 10,
              height: 60,
              fontWeight: isSelected ? "bold" : "normal",
            }}
            id={option._id}
            key={String(option._id)}
            color={isSelected ? "primary" : "default"}
            className={isSelected ? "MuiButton-outlinedPrimary" : ""}
            onClick={(e) => handleClick(e, option)}
          >
            {option?.text}
          </Button>
        );
      })}
    </div>
  );
}
