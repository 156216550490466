import React, { useState, useEffect, createContext, useCallback } from "react";
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute,
  // CognitoUserAttribute,
} from "amazon-cognito-identity-js";
// import AWSCognito from "aws-sdk";
import { KEY_TOKEN } from "../config/constants";
import { decodeJWT, isValidToken, setSession } from "../utils/jwtUtil";

const AuthContext = createContext();

const cognitoConfig = {
  userPoolId: process.env.REACT_APP_POOL_ID,
  clientId: process.env.REACT_APP_CLIENT_ID,
};

export const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || "",
  ClientId: cognitoConfig.clientId || "",
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      const oAuthToken =
        window.location?.hash &&
        window.location?.hash?.split("id_token=")[1]?.split("&")[0];

      if (oAuthToken && isValidToken(oAuthToken)) {
        const jwt = decodeJWT(oAuthToken);

        setSession(oAuthToken);
        setUser({
          name: jwt?.name,
          email: jwt?.email,
        });

        return (window.location.href = "/dashboard");
      }

      const accessToken = window.localStorage.getItem(KEY_TOKEN);
      setSession(accessToken);

      if (accessToken && isValidToken(accessToken)) {
        const payload = decodeJWT(accessToken);

        setUser({
          name: payload.name,
          email: payload.email,
        });
      }
    };

    initialize();
  }, []);

  const getUserAttributes = useCallback(
    (currentUser) =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              setSession(null);
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session?.getIdToken().getJwtToken();

              setSession(token);
              setUser({
                name: attributes.name,
                email: attributes.email,
              });

              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          console.log("deslogado");
          setSession(null);
        }
      }),
    [getUserAttributes]
  );

  function login(email, password) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess", data);
          getSession();
          resolve(data);
        },
        onFailure: (err) => {
          console.log("onFailure", err);
          reject(err);
        },
        newPasswordRequired: () => {
          // Handle this on login page for update password.
          resolve({ message: "newPasswordRequired" });
        },
      });
    });
  }

  function logout() {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
    }

    window.localStorage.removeItem(KEY_TOKEN);
    setSession(null);
    setUser(null);
  }

  function forgotPassword(email) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      user.forgotPassword({
        onSuccess: (resp) => {
          console.log("forgotPassword resp ", resp);
          resolve();
        },
        onFailure: (err) => {
          console.log("onFailure", err);
        },
      });
    });
  }

  function resetPassword(email, code, password) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      user.confirmPassword(code, password, {
        onSuccess: (resp) => {
          console.log("confirmPassword resp ", resp);
          resolve();
        },
        onFailure: (err) => {
          console.log("confirmPassword err ", err);
          reject(err);
        },
      });
    });
  }

  const register = (email, password, name, telephone) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "name",
            Value: name,
          }),
          // new CognitoUserAttribute({
          //   Name: "custom:username",
          //   Value: username,
          // }),
          // new CognitoUserAttribute({
          //   Name: "custom:telephone",
          //   Value: telephone,
          // }),
        ],
        [],
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
          // window.location.href = PATH_AUTH.login;
        }
      );
    });

  const verificationCode = (username, code) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });

      // user.getAttributeVerificationCode("email", {
      //   onSuccess: function (result) {
      //     console.log("call result: " + result);
      //     return resolve(result);
      //   },
      //   onFailure: function (err) {
      //     // alert(err.message || JSON.stringify(err));
      //     console.log("ERROR getAttributeVerificationCode ", err);
      //     return reject(err);
      //   },
      //   // inputVerificationCode: function () {
      //   //   var verificationCode = prompt("Please input verification code: ", "");
      //   //   user.verifyAttribute("email", verificationCode, this);
      //   // },
      // });

      user.confirmRegistration(code, true, (err, result) => {
        if (err) {
          console.log("ERROR confirmRegistration ", err);
          reject(err);
          return;
        }
        resolve(result);
      });
    });

  const sendCode = (email) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      user.resendConfirmationCode({
        onSuccess: function (result) {
          console.log("call result: " + result);
          return resolve(result);
        },
        onFailure: function (err) {
          // alert(err.message || JSON.stringify(err));
          console.log("ERROR resendConfirmationCode ", err);
          return reject(err);
        },
      });
    });

  function isAuthenticated() {
    const accessToken = window.localStorage.getItem(KEY_TOKEN);
    return !!isValidToken(accessToken);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        verificationCode,
        sendCode,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { useAuth, AuthProvider };
