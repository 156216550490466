// import { useMemo } from "react";
import { Avatar, Button, Typography } from "@material-ui/core";
import {
  Container,
  ContentInfo,
  Logo,
  // Menu,
  // MenuItem,
  UserInfo,
} from "./styles";

import logo from "../../assets/youvisa-white.png";
import { useAuth } from "../../contexts/auth";

// const path = window.location.pathname;
export default function Header({ isPublicRouter }) {
  const { user, logout } = useAuth();
  // const routes = useMemo(
  //   () => [
  //     // {
  //     //   name: "Ficha",
  //     //   path: "/visa/form",
  //     // },
  //     {
  //       name: "Meus vistos",
  //       path: "/dashboard",
  //     },
  //   ],
  //   []
  // );

  return (
    <Container>
      <Logo src={logo} />
      {!isPublicRouter && (
        <>
          {/* <Menu>
            <ul>
              {routes.map((route) => (
                <li key={route.name}>
                  <MenuItem
                    pathactived={(path === route.path).toString()}
                    to={route.path}
                  >
                    <Typography variant="subtitle1">{route.name}</Typography>
                  </MenuItem>
                </li>
              ))}
            </ul>
          </Menu> */}

          <UserInfo>
            <Avatar>{user?.name?.split(" ")[0].substring(0, 2)}</Avatar>
            <ContentInfo>
              <Typography variant="body1">{user?.name}</Typography>
              <Button onClick={logout}>Sair</Button>
            </ContentInfo>
          </UserInfo>
        </>
      )}
    </Container>
  );
}
