import { Typography } from "@material-ui/core";
import ReactModal from "react-modal";

export default function ModalComponent({ open, onClose, title, children }) {
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      style={{
        overlay: {
          zIndex: 10,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          margin: "auto",
          display: "flex",
          maxWidth: 330,
          maxHeight: 350,
        },
      }}
    >
      <div>
        <Typography variant="h6" style={{ marginBottom: 20 }}>
          <b>{title}</b>
        </Typography>
        {children}
      </div>
    </ReactModal>
  );
}
