import styled from "styled-components";
import theme from "../../../styles/theme";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;

  section:nth-child(1) {
    background-image: linear-gradient(${theme.primaryLight}, #187441);
    width: 36%;
    padding: 40px;

    .welcome-container {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  section:nth-child(2) {
    width: 64%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiTypography-subtitle2 {
    font-weight: bold;
    color: #f45;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    height: 95vh;

    section:nth-child(1) {
      text-align: center;
      max-height: 200px;
      width: 100%;
      background-image: none;
      padding: 0px;

      .welcome-container {
        display: none;
      }
    }
    section:nth-child(2) {
      width: 90%;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;

  .login-text {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  .sign-container {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    button:nth-child(2) {
      margin-top: 10px;
    }
  }
  .sign-social-auth-text {
    display: flex;
    align-items: center;
    width: 100%;

    hr {
      flex: 1;
    }

    h6 {
      padding: 0 10px;
    }
  }

  .sign-social-auth {
    display: flex;
    width: 100%;
    margin-top: 25px;

    button:nth-child(1) {
      margin-right: 5px;
    }
    button:nth-child(2) {
      margin-left: 5px;
    }
  }
`;
