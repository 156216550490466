import { createContext, useContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const SnackbarContext = createContext();

function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbar({
            open: false,
            message: "",
            severity: "",
          })
        }
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}

function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }

  return context;
}

export { SnackbarProvider, useSnackbar };
