import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import Modal from "react-modal";

import Routes from "./routes";
import { FormCurrentPageProvider } from "./contexts/formCurrentPage";
import { AuthProvider } from "./contexts/auth";
import { SnackbarProvider } from "./contexts/snackbar";

import { themeApplication } from "./styles/customTheme";

Modal.setAppElement("#root");

function App() {
  return (
    <ThemeProvider theme={themeApplication}>
      <AuthProvider>
        <FormCurrentPageProvider>
          <SnackbarProvider>
            <Router>
              <Routes />
            </Router>
          </SnackbarProvider>
        </FormCurrentPageProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
