import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useAuth } from "../../contexts/auth";
import ModalComponent from ".";
import StringUtil from "../../utils/stringUtil";

function ResetPassword({ history, username, openModal, callback }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { resetPassword } = useAuth();

  function validateForm() {
    if (code.length === 0) {
      setError("Código é obrigatório");
      return false;
    }

    if (password.length === 0) {
      setError("Senha é obrigatória");
      return false;
    }

    if (confirmPassword.length === 0) {
      setError("Confirmação de senha é obrigatória");
      return false;
    }

    if (password !== confirmPassword) {
      setError("Senhas não conferem");
      return false;
    }

    if (!new StringUtil().senhaValida(password)) {
      setError(
        "Senha inválida, sua senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial"
      );
      return false;
    }

    return true;
  }

  async function confirmCode() {
    setError(null);
    if (validateForm()) {
      setLoading(true);
      try {
        await resetPassword(username, code, password);
        setLoading(false);

        await callback();

        return history.push("/auth/signin");
      } catch (error) {
        setLoading(false);
        setError("Erro ao validar código");
        console.log("error confirmCode", error);
      }
    }
  }

  return (
    <ModalComponent
      open={openModal}
      onClose={() => history.push("/auth/signin")}
      title="Verifique seu e-mail!"
    >
      <div>
        <Typography variant="subtitle1" align="justify">
          Nós enviamos um código por e-mail para você. Digite abaixo para
          confirmar sua conta e em seguida defina sua nova senha.
        </Typography>

        <TextField
          variant="outlined"
          label="Código"
          fullWidth
          style={{ marginBottom: 12, marginTop: 12 }}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <div className="container-password">
          <TextField
            fullWidth
            type="password"
            variant="outlined"
            label="Senha"
            style={{ marginBottom: 12 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            fullWidth
            type="password"
            variant="outlined"
            label="Confirmar senha"
            style={{ marginBottom: 20 }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        {error && (
          <Box mb={2}>
            <Typography variant="body1" align="center" style={{ color: "red" }}>
              <strong> {error}</strong>
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={confirmCode}
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={20} color="white" /> : "Confirmar"}
        </Button>
      </div>
    </ModalComponent>
  );
}

export default ResetPassword;
