import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@material-ui/core";

import { Alert, Skeleton } from "@material-ui/lab";

import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import RenderForm from "../../components/RenderForm";
import ResultProfileTeste from "./ResultProfileTeste";

import { useFormCurrentPage } from "../../contexts/formCurrentPage";

import {
  GET_FORM_PROFILE_TEST,
  SAVE_FORM_PROFILE_TEST,
} from "../../services/ProfileTestService";
import { TitleQuestion } from "./styles";

export default function ProfileTest() {
  const [answers, setAnswers] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(1);
  const [pages, setPages] = useState(null);
  const [labelNameError, setLabelNameError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resultProfileTest, setResultProfileTest] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(1);

  const isMobile = useMediaQuery("(max-width:600px)");

  const formIdRef = useRef();

  const { formCurrentPage, setFormCurrentPage } = useFormCurrentPage();

  const useStyles = makeStyles((theme) => ({
    customStepper: {
      "& .MuiStepper-root": {
        padding: 10,
        marginBottom: 20,
      },
    },
    customLabelStepper: {
      "& .MuiStepLabel-label": {
        fontSize: isMobile ? 10 : 14,
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    async function getForm() {
      setLoading(true);
      try {
        const response = await GET_FORM_PROFILE_TEST();

        formIdRef.current = response.data._id;
        setPages(response.data.pages);

        const filteredSteps = response.data.pages?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t?.group === thing?.group)
        );

        let groups = [];
        for (let stp of filteredSteps) {
          groups.push(stp.group);
        }

        setSteps(groups);
      } catch (error) {
        console.log("ERROR ", error);
      } finally {
        setLoading(false);
      }
    }

    getForm();
  }, []);

  useEffect(() => {
    let filteredPage = pages?.find((page) => page.order === currentOrder);

    setFormCurrentPage(filteredPage);
    setActiveStep(steps.findIndex((step) => step === formCurrentPage?.group));
  }, [currentOrder, formCurrentPage?.group, pages, setFormCurrentPage, steps]);

  const isLastPage = useMemo(() => {
    return currentOrder === pages?.length;
  }, [currentOrder, pages?.length]);

  async function submitForm() {
    setLabelNameError(null);
    setLoadingSubmit(true);

    const answersFinished = [
      ...answers,
      {
        question_id: formCurrentPage?.questions[0]?._id,
        answers: formCurrentPage?.questions[0]?.answer,
      },
    ];

    try {
      const body = {
        form_id: formIdRef.current,
        answers: answersFinished,
      };

      const response = await SAVE_FORM_PROFILE_TEST(body);

      setResultProfileTest(response.data);
    } catch (error) {
      console.log("ERROR ", error);
      setLabelNameError("Erro ao enviar informações.");
    } finally {
      setLoadingSubmit(false);
    }
  }

  async function nextAnswer() {
    if (verifyIfUserCanProceed()) {
      const nextPage = formCurrentPage?.next;
      const previousPage = formCurrentPage?.order;

      if (isLastPage) {
        return submitForm();
      }

      setPages((oldPages) => {
        const newPages = [...oldPages];
        const newPage = newPages.find((page) => page.order === nextPage);
        newPage.previous = previousPage;

        newPages[newPages.findIndex((page) => page.order === currentOrder)] = {
          ...formCurrentPage,
        };

        return newPages;
      });

      setCurrentOrder(nextPage);
      setAnswers((oldAnswers) => [
        ...oldAnswers,
        {
          question_id: formCurrentPage?.questions[0]?._id,
          answers: formCurrentPage?.questions[0]?.answer,
        },
      ]);
    }
  }

  async function previousAnswer() {
    setLabelNameError(null);
    setCurrentOrder(formCurrentPage?.previous);
  }

  function verifyIfUserCanProceed() {
    setLabelNameError(null);
    let isValid = true;

    if (formCurrentPage?.errorInputMessage) {
      isValid = false;
    }

    formCurrentPage?.questions?.every((question) => {
      if (!question?.answer) {
        console.log("question ", question);
        question.category === "ENUM"
          ? setLabelNameError(
              "Por favor, escolha a opção acima para seguir com o seu teste."
            )
          : setLabelNameError(
              "Por favor, preencha a pergunta acima para seguir com o seu teste."
            );

        isValid = false;
        return false;
      }

      return true;
    });
    return isValid;
  }

  return (
    <>
      <Header isPublicRouter />
      <Container maxWidth="md">
        {!resultProfileTest && (
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} classes={{ root: classes.customStepper }}>
                <StepLabel
                  style={{
                    fontSize: isMobile ? 12 : 14,
                  }}
                  classes={{ root: classes.customLabelStepper }}
                >
                  {isMobile ? (activeStep === index ? label : "") : label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Container>

      {resultProfileTest ? (
        <Container maxWidth="lg">
          <ResultProfileTeste result={resultProfileTest} />
        </Container>
      ) : (
        <Container maxWidth="sm">
          {loading ? (
            <Skeleton variant="text" width="100%" height={4} />
          ) : (
            <ProgressBar
              value={(formCurrentPage?.order / pages?.length) * 100}
            />
          )}

          <Grid container style={{ marginBottom: 25 }}>
            {loading ? (
              <Skeleton
                variant="text"
                width={"100%"}
                height={28}
                style={{ marginTop: 40 }}
              />
            ) : (
              <TitleQuestion>{formCurrentPage?.title}</TitleQuestion>
            )}
          </Grid>

          {loading ? (
            <Skeleton variant="rect" width={"100%"} height={56} />
          ) : (
            <Grid container spacing={3}>
              {formCurrentPage?.questions?.map((question, index) => (
                <Grid
                  key={String(index)}
                  item
                  className={`MuiGrid-grid-sm-${question.width} MuiGrid-grid-xs-12`}
                >
                  <RenderForm index={index} onSaveAnswer={() => {}} />
                </Grid>
              ))}
            </Grid>
          )}

          <div style={{ margin: "20px 0" }}>
            {(formCurrentPage?.errorInputMessage || labelNameError) && (
              <Alert severity="error">
                {formCurrentPage?.errorInputMessage ?? labelNameError}
              </Alert>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            {currentOrder > 1 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={previousAnswer}
                style={{ width: "40%" }}
              >
                Voltar
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={nextAnswer}
              style={{ width: "40%" }}
              disabled={loading || loadingSubmit}
            >
              {loading || loadingSubmit ? (
                <CircularProgress size={30} />
              ) : isLastPage ? (
                "Finalzar"
              ) : (
                "Próxima"
              )}
            </Button>
          </div>
        </Container>
      )}
    </>
  );
}
