export default class StringUtil {
  senhaValida(password) {
    let retorno = false;
    let letrasMaiusculas = /[A-Z]/;
    let letrasMinusculas = /[a-z]/;
    let numeros = /[0-9]/;
    let caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

    let auxMaiuscula = 0;
    let auxMinuscula = 0;
    let auxNumero = 0;
    let auxEspecial = 0;
    for (var i = 0; i < password.length; i++) {
      if (letrasMaiusculas.test(password[i])) auxMaiuscula++;
      else if (letrasMinusculas.test(password[i])) auxMinuscula++;
      else if (numeros.test(password[i])) auxNumero++;
      else if (caracteresEspeciais.test(password[i])) auxEspecial++;
    }
    if (auxMaiuscula > 0) {
      if (auxMinuscula > 0) {
        if (auxNumero > 0) {
          if (auxEspecial) {
            retorno = true;
          }
        }
      }
    }

    return retorno;
  }
}
